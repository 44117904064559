import request from "@/utils/request";

/*用户是否实名认证*/
function postIsRealNameAuthen(data) {
  return request({
    url: "/consume/real-name-authen/queryRNA",
    method: "POST",
    data
  });
}
/* 获取实名认证信息 */
function postqueryRealNameInfo(data) {
  return request({
    url: "/consume/real-name-authen/queryRealNameInfo",
    method: "POST",
    data
  });
}
export {
  postIsRealNameAuthen,
  postqueryRealNameInfo
};