
import request from "@/utils/request";

//获取图片地址
function getImgUrl(objectKey) {
  return request({
    url: `/backend-common/third/batchQueryUrl?objectKeyList=${objectKey}`
  });
}

export { getImgUrl };