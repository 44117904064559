<!--
 * @Description: 
 * @Version: 2.0
 * @Date: 2022-06-30 11:24:23
 * @LastEditors: Fiona.xu
 * @LastEditTime: 2022-07-07 17:21:35
-->
<!--  -->
<template>
  <custom-page-wrapper
    :needLoginAuth="needLoginAuth"
    title="办事项申请"
    titleBgColor="#fff"
    titleColor="#000"
    pageBgColor="#FFF"
  >
    <view class="page-add">
      <view class="step">
        <u-steps
          :list="numList"
          :current="current"
          active-color="#3C37D2"
        ></u-steps>
      </view>
      <view class="notice" v-if="current == 0">
        <view class="page">
          <view class="notice-page">
            <u-form
              :model="model1"
              ref="uForm"
              label-position="top"
              label-width="200"
            >
              <u-form-item label="办事项名称" required prop="itemName">
                <u-input v-model.trim="model1.itemName" disabled />
              </u-form-item>
              <u-form-item label="联系人姓名" required prop="contactUsr">
                <u-input v-model.trim="model1.contactUsr" />
              </u-form-item>
              <u-form-item label="联系人手机号" required prop="contactPhe">
                <u-input v-model.trim="model1.contactPhe" />
              </u-form-item>
              <u-form-item label="联系人电话" prop="contactTel">
                <u-input v-model.trim="model1.contactTel" />
              </u-form-item>
              <u-form-item label="联系人身份证" prop="contactCard">
                <u-input v-model.trim="model1.contactCard" />
              </u-form-item>
            </u-form>
          </view>
          <div class="notice-page">
            <view class="title">收取材料</view>
            <view class="hr"></view>
            <view
              class="list"
              v-if="
                detialInfo.applyMaterialList &&
                detialInfo.applyMaterialList.length > 0
              "
            >
              <view
                class="item"
                v-for="(item, i) in detialInfo.applyMaterialList"
                :key="i"
              >
                <view class="name">{{ i + 1 + "." + item.materialName }}</view>
              </view>
            </view>

            <van-empty v-else description="数据为空"> </van-empty>
          </div>
          <view class="remark">
            注：本人对办理该事项所提供的申报材料实质内容的真 实性负责。</view
          >
        </view>
      </view>

      <view class="apply" v-if="current == 1">
        <view class="page">
          <view class="row-title">申报表单</view>
          <view v-if="demoForm.length">
            <view v-for="(item, i) in demoForm" :key="i">
              <talent-form-item
                v-if="supportForm.indexOf(item.type) > -1"
                :ref="`talent-form-${i}`"
                :formData="item"
                @addFormFields="addFormFields"
              />
            </view>
          </view>
          <van-empty v-else description="数据为空"> </van-empty>
        </view>

        <view class="page">
          <view class="row-title">申报材料</view>
          <view
            class="list"
            v-if="
              detialInfo.applyMaterialList &&
              detialInfo.applyMaterialList.length > 0
            "
          >
            <view
              class="item"
              v-for="(item, i) in detialInfo.applyMaterialList"
              :key="i"
            >
              <view class="title-box">
                <view class="title">{{ i + 1 + "." + item.materialName }}</view>
                <view class="upload-btn" @click="handleUpload(i, item)"
                  >网上提交</view
                >
              </view>
              <view
                class="upload-list"
                v-for="(sub, b) in materialList[i].files"
                :key="b"
              >
                <view class="name">{{ sub.fileName }}</view>
                <view class="opt-box">
                  <view class="down-btn" @click="handleDown(sub.url)"
                    >下载</view
                  >
                  <view class="delete-btn" @click="handleDelete(b, i)"
                    >删除</view
                  >
                </view>
              </view>
              <view class="hr"></view>
              <view class="upload-list"></view>
              <view class="down-box">
                <view
                  class="empty"
                  v-for="(main, k) in item.blankChartUrls"
                  :key="k"
                  @click="handleDown(main.url)"
                >
                  空表下载

                  <icon-font
                    color="#3c37d2"
                    icon="icondirection-down"
                  ></icon-font>
                </view>
                <view
                  class="example"
                  v-for="(main, k) in item.sampleChartUrls"
                  :key="k"
                  @click="handleDown(item.url)"
                >
                  样表下载

                  <icon-font
                    color="#3c37d2"
                    icon="icondirection-down"
                  ></icon-font>
                </view>
              </view>
            </view>
          </view>
          <van-empty v-else description="数据为空"> </van-empty>
        </view>
      </view>

      <view class="result" v-if="current == 2">
        <div class="apply-result">
          <view class="list">
            <view class="name">受理完成!</view>
            <view class="hr"></view>
            <view class="result-box">
              <!-- <view class="lable">受理单号</view> -->
              <view class="code">{{ code }}</view>
            </view>
          </view>
        </div>
      </view>

      <button class="btn" @click="handleNext()">
        {{ content }}
      </button>
    </view>
    <u-toast ref="uToast" />
  </custom-page-wrapper>
</template>

<script>
import { postqueryRealNameInfo } from "@/api/consume/realNameAuthen";
import { addApply } from "@/api/policy/itemApplyInfo";
import { getItemInfo, getApplyForms } from "@/api/policy/item";
import uploadFile from "@/utils/uploadFile";
// import { saveFileToLocal } from "@/utils/uni-api";
import TalentFormItem from "./components/talentFormItem.vue";

export default {
  // 必须要在onReady生命周期，因为onLoad生命周期组件可能尚未创建完毕
  onReady() {},
  data() {
    return {
      itemid: "",
      formKey: "",
      formId: "",
      content: "下一步",
      current: 0,
      basicInfo: null,
      detialInfo: null,
      numList: [
        {
          name: "申报须知",
        },
        {
          name: "申报信息",
        },
        {
          name: "申报完成",
        },
      ],
      needLoginAuth: false,
      model1: {
        itemName: "",
        contactUsr: "",
        contactPhe: "",
        contactTel: "",
        contactCard: "",
      },
      rules: {
        itemName: [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur",
          },
        ],
        contactUsr: [
          {
            required: true,
            message: "请输入联系人姓名",
            trigger: "blur",
          },
        ],
        contactPhe: [
          {
            required: true,
            message: "请输入联系人手机",
            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
            trigger: "blur",
          },
        ],
        contactCard: [
          {
            required: false,
            message: "请输入联系人身份证",
            pattern:
              /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/,
            trigger: "blur",
          },
        ],
      },
      //申报信息-材料
      fileList: [],
      materialList: [],
      //申报信息-表单
      demoForm: [],
      supportForm: [
        "input",
        "select",
        "datePicker",
        "timePicker",
        "cascader",
        "switch",
        "checkbox",
      ],
      formfields: {},
      formValues: [],
      isValidating: false,
      validatingStatus: true,
      //受理单号
      code: "",
    };
  },
  onLoad(options) {
    this.itemId = options.itemId;
    this.formId = options.formId;
    this.formKey = options.formKey;
    this.getDetial(this.itemId);
    this.getApplyForms();
    this.clearFormFields();
  },
  watch: {
    current(val) {
      if (val == 2) {
        this.content = "完成";
      } else {
        this.content = "下一步";
      }
    },
  },
  components: {
    TalentFormItem,
  },

  computed: {},

  mounted() {
    this.getqueryRealNameInfo(); //检查实名认证状态
  },

  methods: {
    /* 查询实名认证 */
    getqueryRealNameInfo() {
      postqueryRealNameInfo().then((res) => {
        let { data } = res;
        this.model1.contactUsr = data.rnName;
        this.model1.contactPhe = data.rnPhone;
        this.model1.contactCard = data.rnIdcard;
      });
    },
    /* 获取详情 */
    getDetial(id) {
      getItemInfo({ itemId: id }).then((res) => {
        let { data, success } = res;
        if (success) {
          this.detialInfo = data ? data : null;
          this.model1.itemName = this.detialInfo.itemName;
          this.materialList = this.detialInfo.applyMaterialList.map((e) => {
            return {
              files: [],
            };
          });
        }
      });
    },
    //获取申报信息表单
    getApplyForms() {
      getApplyForms({ formId: this.formId }).then((res) => {
        let { success, data } = res;
        if (success) {
          let { formContent } = data;
          if (formContent) {
            let forms = JSON.parse(formContent);
            this.demoForm = forms;
            console.log("getApplyForms", { forms });
          }
        }
      });
    },
    /* 获取 */
    sendBasic(obj) {
      this.basicInfo = obj;
    },
    //处理自定义表单数据
    formatFormvalues() {
      if (!this.demoForm.length) {
        this.formValues = [];
      }

      let arr = [],
        len = this.demoForm.length;
      for (let i = 0; i < len; i++) {
        let formItem = this.$refs[`talent-form-${i}`][0];
        let { formTitle, formValue, formType } = formItem;
        arr.push({
          formType: formType,
          formName: formTitle,
          formValue: {
            value: formValue,
            label: formValue,
          },
        });
      }

      this.formValues = arr;
      console.log("formValues", this.formValues);
    },
    handleNext() {
      //申报须知
      if (this.current == 0) {
        if (this.validBasic()) {
          this.current = 1;
          return;
        }
        return;
      }

      //申报信息
      if (this.current == 1) {
        //校验表单
        this.validateFields();
        if (this.validatingStatus === "success") {
          let fileStatus = true;
          this.materialList.forEach((item) => {
            if (!item.files.length) {
              fileStatus = false;
            }
          });
          if (fileStatus) {
            this.add();
          } else {
            this.showToast("未获取到上传材料", "error");
          }
          return;
        }
      }

      //申报完成
      if (this.current == 2) {
        uni.redirectTo({
          url: "/subpackages/talent/progress/index",
        });
      }
    },
    validBasic() {
      if (!this.model1.contactUsr) {
        this.showToast("请输入联系人", "error");
        return;
      }
      if (!this.model1.contactPhe) {
        this.showToast("请输入手机号", "error");
        return;
      } else if (!/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(this.model1.contactPhe)) {
        this.showToast("输入手机号格式不正确", "error");
        return;
      }
      if (this.model1.contactCard) {
        if (
          !/^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/.test(
            this.model1.contactCard
          )
        ) {
          this.showToast("输入身份证格式不正确", "error");
          return;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    showToast(title, type) {
      this.$refs.uToast.show({
        title: title,
        type: type,
      });
    },
    /* 删除 */
    handleDelete(b, i) {
      this.materialList[i].files.splice(b, 1);
    },
    handleUpload(i, item) {
      uni.showLoading({
        title: "上传中...",
        mask: true,
      });
      uploadFile.postImg().then((res) => {
        uni.hideLoading();
        let { data } = res;
        if (data) {
          if (!this.materialList[i].files) {
            this.materialList[i] = { files: [] };
          }
          this.materialList[i].files.push({
            url: data.url,
            fileName: data.fileName,
            objectKey: data.objectKey,
            applyMaterialId: item.id,
          });

          uni.showToast({
            title: "上传成功",
            icon: "success",
            mask: true,
            duration: 1000,
          });
        } else {
          uni.showToast({
            title: "上传失败",
            icon: "warn",
            mask: true,
            duration: 1000,
          });
        }
      });
    },
    /*下载 */
    handleDown(url) {
      saveFileToLocal(url);
    },
    add() {
      this.formatFormvalues();
      const arr = this.materialList.map((el) => {
        return {
          applyMaterialId: el.files[0].applyMaterialId,
          materialCode: el.files.map((i) => i.objectKey).join(","),
        };
      });
      const params = {
        ...this.model1,
        type: 2,
        itemId: this.itemId,
        itemName: this.detialInfo.itemName,
        cmpyCode: "",
        cmpyName: "",
        creditCode: "",
        legalPerson: "",
        formKey: this.formKey,
        formValue: JSON.stringify(this.formValues),
        materialList: arr,
      };

      addApply(params).then((res) => {
        let { data, success } = res;

        if (data) {
          this.code = data;
          this.current = 2;
          this.showToast("办事项申请成功", "success");
        } else {
          this.showToast("办事项申请失败", "error");
        }
      });
    },
    /*
     *@des: 自定义表单
     *@params:
     *@return:
     */
    /*
     *@des: 校验所有表单
     *@params:
     *@return:
     */
    validateFields() {
      // 1.开始校验
      this.isValidating = true;

      // 2.无表单，返回
      let len = this.demoForm.length;
      if (len === 0) {
        this.isValidating = false;
        return;
      }

      // 3.初始校验状态
      this.validatingStatus = "success";

      // 4.循环校验子表单
      for (let i = 0; i < len; i++) {
        //单个表单校验失败，结束校验
        if (this.validatingStatus === "fail") {
          this.isValidating = false;
          return;
        }

        let field = this.$refs[`talent-form-${i}`][0];
        field.validate(this.validateCb);
      }

      // 5.结束校验
      this.isValidating = false;
    },
    /*
     *@des: 获取子表单校验信息，如果有值，校验失败，改变校验状态
     *@params: {message} String
     *@return:
     */
    validateCb(message) {
      if (message) {
        this.validatingStatus = "fail";
      }
    },
    toJSON() {
      return this;
    },
  },
};
</script>
<style lang="scss" scoped>
.page-add {
  width: 100%;
  min-height: 100%;
  background: #f9f9f9;
  display: flex;
  flex-direction: column;
  .step {
    padding: 68rpx 30rpx;
  }
  .notice {
    .page {
      .notice-page {
        min-height: 253rpx;
        background: #ffffff;
        border-radius: 16rpx;
        padding: 30rpx 0rpx 40rpx 30rpx;
        margin: 0rpx 30rpx 30rpx 30rpx;
        .title {
          height: 36rpx;
          font-size: 34rpx;
          margin: 0rpx 0rpx 30rpx 30rpx;
          color: #333333;
        }
        .hr {
          width: 630rpx;
          height: 1rpx;
          background: #e5e5e5;
        }
        .list {
          .item {
            margin: 30rpx 30rpx 0rpx 30rpx;
            .name {
              height: 28rpx;
              font-size: 26rpx;
              font-weight: 400;
              color: #333333;
              line-height: 28rpx;
            }
          }
        }
      }
      .remark {
        height: 90rpx;
        font-size: 26rpx;
        font-weight: 400;
        color: #666666;
        line-height: 45rpx;
        margin: 30rpx 30rpx;
      }
    }
  }
  .basic {
    background: #ffffff;
    .page {
      min-height: 253rpx;
      background: #ffffff;
      border-radius: 16rpx;
      padding: 30rpx 0rpx 40rpx 30rpx;
      margin: 0rpx 30rpx;
    }
  }
  .apply {
    .page {
      min-height: 253rpx;
      background: #fff;
      border-radius: 16rpx;
      margin: 30rpx 30rpx;
      padding: 0rpx 30rpx;
      .list {
        .item {
          background: #fff;
          padding: 30rpx 30rpx 0rpx 30rpx;
          margin-top: 30rpx;
          .hr {
            width: 630rpx;
            height: 1rpx;
            background: #e5e5e5;
            margin-top: 20rpx;
          }
          .title-box {
            display: flex;
            justify-content: space-between;
            .title {
              height: 36rpx;
              font-size: 34rpx;
              margin: 0rpx 0rpx 30rpx 0rpx;
              color: #333333;
              width: 440rpx;
            }
            .upload-btn {
              width: 144rpx;
              height: 60rpx;
              background: #3c37d2;
              border-radius: 50rpx;
              color: #fff;
              line-height: 60rpx;
              text-align: center;
              font-size: 24rpx;
              font-weight: 400;
            }
          }
          .upload-list {
            display: flex;
            justify-content: space-between;
            margin-top: 20rpx;
            .name {
              width: 286rpx;
              height: 32rpx;
              font-size: 26rpx;
              font-weight: 400;
              color: #333333;
              line-height: 32rpx;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .opt-box {
              display: flex;
              width: 130rpx;

              .delete-btn {
                font-size: 26rpx;
                font-weight: 400;
                color: #666666;
                line-height: 32rpx;
              }
              .down-btn {
                font-size: 26rpx;
                font-weight: 400;
                color: #666666;
                line-height: 32rpx;
                margin-right: 20rpx;
              }
            }
          }
          .down-box {
            display: flex;
            height: 80rpx;
            line-height: 80rpx;
            color: #3c37d2;
            font-size: 26rpx;
            .empty {
              margin-right: 40rpx;
            }
          }
        }
      }
    }
    .row-title {
      position: relative;
      width: auto;
      height: 30rpx;
      box-sizing: content-box;
      padding: 30rpx 0rpx 20rpx 0rpx;
      font-size: 30rpx;
      line-height: 30rpx;
      font-weight: bold;
      color: #333;
    }
    .row-title::after {
      content: "";
      position: absolute;
      left: 0rpx;
      bottom: -5rpx;
      width: 40rpx;
      height: 4rpx;
    }
  }
  .result {
    .apply-result {
      min-height: 253rpx;
      background: #f9f9f9;
      border-radius: 16rpx;
      padding: 0rpx 0rpx 40rpx 30rpx;
      margin: 30rpx 30rpx;
      .list {
        background: #fff;
        padding: 30rpx;
        .name {
          font-size: 34rpx;
          font-weight: 500;
          color: #333333;
          line-height: 100rpx;
          text-align: center;
        }
        .hr {
          width: 630rpx;
          height: 1rpx;
          background: #e5e5e5;
          margin: 20rpx 0rpx;
        }
        .result-box {
          .lable {
            height: 40rpx;
            font-size: 28rpx;

            font-weight: 400;
            color: #999999;
            line-height: 40rpx;
          }
          .code {
            margin-top: 8rpx;

            min-height: 42rpx;
            font-size: 30rpx;
            font-weight: 400;
            color: #333333;
            line-height: 42rpx;
          }
        }
      }
    }
  }
  .btn {
    width: 690rpx;
    height: 80rpx;
    background: #3c37d2;
    box-shadow: 0rpx 0rpx 10rpx 0rpx rgba(200, 200, 200, 0.5);
    border-radius: 40rpx;
    color: #fff;
    margin: 30rpx 30rpx;
    font-size: 28rpx;
    font-weight: 400;
  }
}
</style>
