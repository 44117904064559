<template>
  <view class="subsidy-formitem">
    <!-- 提示 -->
    <view class="form-header" v-if="formData.formWarning">{{
      formData.formWarning
    }}</view>
    <view
      :class="[
        'form-main',
        formData.formType === 'imgUpload' ? 'form-main-block' : ''
      ]"
    >
      <view class="item-title">
        <text>{{ formData.title }}</text>
        <!-- 是否必填 -->
        <text
          class="required-icon"
          v-if="isRequired"
          >*</text
        >
      </view>
      <view class="item-form">
        <!-- 输入框 -->
        <input
          v-if="formData.type === 'input'"
          class="form-input"
          :placeholder="formData.props.placeholder || '请输入'"
          placeholder-style="color: #999"
          confirm-type="done"
          :maxlength="formData.props.maxlength || -1"
          :value="inputValue"
          @input="onInput"
        />

        <!-- 下拉选择框 -->
        <picker
          v-else-if="formData.type === 'select'"
          class="form-picker"
          mode="selector"
          :range="formData.options"
          range-key="label"
          :value="pickerIndex"
          @change="onPickerSelect"
        >
          <text
            v-if="pickerIndex !== null"
            class="picker-select"
            style="color: #333; display: inline-block;"
            >{{ formData.options[pickerIndex].label }}</text
          >
          <text v-else class="picker-select" style="color: #999">{{
            formData.props.placeholder || "请选择"
          }}</text>
        </picker>

        <!-- 日期选择框- 天-->
        <picker
          class="form-picker"
          v-if="formData.type === 'datePicker'"
          mode="date"
          fields="day"
          start="1949-10-01"
          end="2099-10-01"
          :value="dateSelect"
          @change="onDateSelect"
        >
          <text class="picker-select" style="color: #999" v-if="!dateSelect">{{
            formData.props.placeholder || "请选择"
          }}</text>
          <text v-else class="picker-select" style="color: #333">{{
            dateSelect
          }}</text>
        </picker>

        <!-- 日期选择框- 时间-->
        <picker
          class="form-picker"
          v-if="formData.type === 'timePicker'"
          mode="time"
          :value="timeSelect"
          @change="onTimeSelect"
        >
          <text class="picker-select" style="color: #999" v-if="!timeSelect">{{
            formData.props.placeholder || "请选择"
          }}</text>
          <text v-else class="picker-select" style="color: #333">{{
            timeSelect
          }}</text>
        </picker>

        <!-- 级联选择器 -->
        <u-select
          class="form-picker"
          v-if="formData.type === 'cascader'"
          v-model="cascaderShow" 
          mode="mutil-column-auto" 
          :list="formData.props.options" 
          @confirm="onCascaderChange"
          >
        </u-select>
        <view v-if="formData.type === 'cascader'" class="form-picker" @click="cascaderShow = true">
          <text class="picker-select" style="color: #999" v-if="!cascaderSelect">{{
            formData.props.placeholder || "请选择"
          }}</text>
          <text v-else class="picker-select" style="color: #333">{{
            cascaderSelect
          }}</text>
        </view>


        <!-- 切换 -->
        <switch v-if="formData.type === 'switch'" :checked="switchChecked" @change="onSwitchChange"/>

        <!-- 多选 -->
        <u-checkbox-group v-if="formData.type === 'checkbox'" :size="24" @change="checkboxGroupChange">
          <u-checkbox 
            v-for="(item, index) in checkBoxs" :key="index" 
            v-model="item.checked" 
            :name="item.label"
            style="font-size: 24rpx"
          >{{item.label}}</u-checkbox>
        </u-checkbox-group>

        <icon-font
            icon="iconarrow-right"
            :size="24"
            color="#999"
            style="margin-left: 10rpx; line-height: 95rpx"
          ></icon-font>
      </view>
    </view>
  </view>
</template>

<script>
import Schema from "async-validator";
import { getImgUrl } from "@/api/backendCommon/third";

export default {
  name: "talent-form-item",
  props: {
    formData: Object
  },
  data() {
    return {
      formName: "",
      formTitle: "",
      inputValue: "",
      pickerIndex: null,
      monthSelect: "",
      dateSelect: "",
      timeSelect: "",
      cascaderSelect: "",
      cascaderShow: false,
      cascaderMap: {
        text:'label', value:'value'
      },
      switchChecked: false,
      checkBoxs: [],
      checkboxGroup: "",
      defalutFileList: [],
      imgFileList: [],
      validator: null
    };
  },
  computed: {
    //表单值
    formValue() {
      switch (this.formData.type) {
        case "input":
          return this.inputValue;
        case "select":
          return this.pickerIndex !== null? this.formData.options[this.pickerIndex].label:"";
        case "datePicker":
          return this.dateSelect;
        case "timePicker":
          return this.timeSelect;
        case "cascader":
          return this.cascaderSelect;
        case "switch":
          return this.switchChecked;
        case "checkbox":
          return this.checkboxGroup;
        default:
          return "";
      }
    },
    // 是否必填
    isRequired(){
      let requiredRule = null;
      let {validate} = this.formData;
      if(validate){
        requiredRule = validate.filter(item => item.mode === "required")[0];
      }

      return (this.formData.$required !== undefined || requiredRule)? true: false;
    }
  },
  created() {
    //初始化
    this.formName = this.formData.field;
    this.formTitle = this.formData.title;
    this.formType = this.formData.type;

    //初始化checkbox
    if(this.formData.type === "checkbox"){
      this.checkBoxs = this.formData.options.map(item => ({...item, checked: false}));
    }
  },
  beforeDestroy() {
    this.resetField();
  },
  methods: {
    /*
     *@des: 表单监听
     *@params:
     *@return:
     */
    //输入框
    onInput(e) {
      this.inputValue = e.detail.value;
    },
    //下拉选择
    onPickerSelect(e) {
      let { detail } = e;
      this.pickerIndex = detail.value;
    },
    //月份选择
    onMonthSelect(e) {
      this.monthSelect = e.detail.value;
    },
    //日期选择
    onDateSelect(e) {
      this.dateSelect = e.detail.value;
    },
    //时间选择
    onTimeSelect(e) {
      this.timeSelect = e.detail.value;
    },
    // 级联选择
    onCascaderChange(e){
      this.cascaderSelect = e.map(item => item.label).join(",");
    },
    // 切换
    onSwitchChange(e){
      this.switchChecked = e.detail.value;
    },
    //多选
    checkboxGroupChange(e){
      console.log({e});
      this.checkboxGroup = e.join(",");
    },
    //图片上传
    imgUpload(files) {
      if (!files || !files.length) {
        this.imgFileList = [];
      }

      let imgs = files.map(el => {
        if (el.response) {
          return el.response.data;
        } else {
          return {
            objectKey: el.objectKey,
            url: el.url
          };
        }
      });
      this.imgFileList = imgs;
    },
    /*
     *@des: 校验当前表单数据，处理校验状态
     *@params:
     *@return:
     */
    validate(callback) {
      if (!this.validator) {
        let { $required, type, pattern, validate } = this.formData;
        let rules = validate? validate.filter(item => item.mode === "pattern") : [];

        this.validator = new Schema({
          [this.formData.field]: [
            {
              required: this.isRequired,
            },
            ...rules.map(item => {
              let {type, pattern} = item;
              return {
                type,
                pattern: pattern && type === "input"? new RegExp(rules.pattern) : undefined,
              };
            })
          ]
        });
      }

      let validateValue = {
        [this.formData.field]: this.formValue
      };

      this.validator.validate(validateValue, (errors, fields) => {
        if (errors && errors.length) {
          let { message } = errors[0];
          //未填写
          if (message.indexOf("required") > -1) {
            message = `${this.formData.title}未填写!`;
          }
          //格式不匹配
          if (message.indexOf("not") > -1) {
            message = `${this.formData.title}格式错误!`;
          }
          uni.showToast({
            title: message,
            icon: "none"
          });

          if (callback) {
            callback(message);
          }
        }
      });
    },
    /*
     *@des: 还原表单实例
     *@params:
     *@return:
     */
    resetField() {
      this.formName = "";
      this.inputValue = "";
      this.pickerIndex = null;
      this.monthSelect = "";
      this.dateSelect = "";
      this.defalutFileList = [];
      this.validator = null;
    },
    /*
     *@des: 获取图片地址
     *@params:
     *@return:
     */
    async getImgUrl(objectKey) {
      let res = await getImgUrl(objectKey);
      let { success, data } = res;
      if (success && data.length) {
        return data[0].url;
      } else {
        return "";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.subsidy-formitem {
  width: auto;
  height: auto;
  border-bottom: 1rpx solid #e3e3e3;
  .form-header {
    margin: 20rpx 0rpx;
    font-size: 24rpx;
    line-height: 33rpx;
    color: #ff2731;
  }
  .form-main {
    width: auto;
    height: 95rpx;
    display: flex;
    justify-content: space-between;
    .item-title {
      margin-right: 35rpx;
      font-size: 24rpx;
      line-height: 95rpx;
      color: #333;
      white-space: nowrap;
      .required-icon {
        color: #ff0000;
      }
    }
    .item-form {
      width: 60%;
      height: 95rpx;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .form-input {
        width: 100%;
        height: 95rpx;
        font-size: 24rpx;
        line-height: 95rpx;
        text-align: right;
        color: #333;
      }
      .form-picker {
        width: 100%;
        height: 95rpx;
        text-align: right;
        .picker-select {
          max-width: 100%;
          font-size: 24rpx;
          line-height: 95rpx;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .form-main-block {
    display: block;
    height: auto;
    .item-title {
      margin-right: 0rpx;
      padding: 30rpx 0rpx;
      line-height: 30rpx;
      white-space: normal;
    }
    .item-form {
      width: 100%;
      height: auto;
    }
  }
}
</style>
