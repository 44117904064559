import request from "@/utils/request";

/* /policy/simpleApply/add */
function addApply(data) {
  return request({
    url: "/policy/item-apply-info/add",
    method: "POST",
    data
  });
}
export {
  addApply
};