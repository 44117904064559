export default {
  getImg() {
    return new Promise((resolve, reject) => {
      uni.chooseMessageFile({
        count: 1,
        headers: { "Content-Type": "multipart/form-data" },
        extension: [
          ".doc",
          ".docx",
          ".pdf",
          ".png",
          ".jpg",
          ".xlsx",
          ".xls",
          ".jpeg"
        ],
        success: res => {
          resolve(res);
        },
        fail: e => {
          uni.hideLoading();
          uni.showToast({
            title: "上传失败",
            icon: "none"
          });
        },
        complete: res => {
          uni.hideLoading();
        }
      });
    });
  },
  postImg() {
    const header = {};
    const token = uni.getStorageSync("token");
    header["User-Source"] = "PC_QY";
    const baseURL = uni.getStorageSync("baseURL"); //正式代码
    if (token) {
      header.Authorization = token;
    }
    return new Promise((resolve, reject) => {
      this.getImg().then(res => {
        uni.uploadFile({
          url:process.env.VUE_APP_BASE_URL+ "/policy/file-manage/uploadFile", 
          header: header,
          timeout: 10 * 1000,
          filePath: res.tempFiles[0].path,
          name: "multipartFile",
          success: res => {
            let { statusCode, data } = res;
            try {
              // 接口调用成功
              data = JSON.parse(data);

              let { code, msg } = data;
              if (code === 200) {
                resolve(data);
              } else {
                wx.showToast({
                  title: msg,
                  duration: 2000, //提示的延迟时间，单位毫秒，默认：1500
                  mask: true, //是否显示透明蒙层，防止触摸穿透，默认：false
                  icon: "none"
                });
                reject(data);
              }
            } catch (e) {
              reject(e);
            }
          },
          fail: respons => {
            reject(respons);
            wx.showToast({
              title: "当前网络异常，请稍后再试",
              icon: "none",
              duration: 2000
            });
          }
        });
      });
    });
  }
};
